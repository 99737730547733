//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-520:_9192,_920,_8316,_9704,_1880,_9728,_3488,_2267;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-520')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-520', "_9192,_920,_8316,_9704,_1880,_9728,_3488,_2267");
        }
      }catch (ex) {
        console.error(ex);
      }