//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-520:_4,_4984,_1884,_4608,_6740,_6048,_9848,_4644;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-520')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-520', "_4,_4984,_1884,_4608,_6740,_6048,_9848,_4644");
        }
      }catch (ex) {
        console.error(ex);
      }