
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('li', { 'className': 'product' }, _createElement('article', {
                    'className': 'card',
                    'data-product-id': this.id
                }, _createElement('div', { 'className': 'card-figure' }, _createElement('div', { 'className': 'themevale_badges' }, !this.out_of_stock && this.stock && (this.stock?.includes('Include Special Order') && !this.stock?.includes('In-stock Only')) ? _createElement('div', {
                    'className': 'specialOrder-badge',
                    'key': '149'
                }, '\n          Special Order\n        ') : null, this.Universal ? _createElement('div', {
                    'className': 'themevale_badge',
                    'key': '407'
                }, _createElement('span', { 'className': 'text' }, 'Universal')) : null), _createElement('a', {
                    'href': this.custom_url,
                    'className': 'product-link ' + (this.images_image_thumbnail2 ? 'image-swap' : ''),
                    'aria-label': 'product link'
                }, _createElement('img', {
                    'className': (this.images_image_thumbnail2 ? 'image-one ' : '') + 'lazyautosizes lazyloaded',
                    'src': this.imageOrDefault(this.resizeImage(this.images_image_thumbnail1)),
                    'data-src': this.images_image_thumbnail1,
                    'data-sizes': 'auto',
                    'alt': this.removeHTML(this.name),
                    'onError': this.onImageError
                }), this.images_image_thumbnail2 ? _createElement('img', {
                    'className': 'image-two lazyautosizes lazyloaded',
                    'src': this.resizeImage(this.images_image_thumbnail2),
                    'data-src': this.images_image_thumbnail2,
                    'data-sizes': 'auto',
                    'alt': this.removeHTML(this.name),
                    'onError': e => this.onImageError(e, 'images_image_thumbnail2'),
                    'key': '1015'
                }) : null), _createElement('div', { 'className': 'card-buttonGroup' }, _createElement('a', {
                    'aria-label': 'quickview',
                    'className': 'card-button-quickview quickview',
                    'data-product-id': this.id
                }, _createElement('svg', { 'title': 'icon eye' }, _createElement('use', { 'xlinkHref': '#icon-eye' })), _createElement('span', { 'className': 'text' }, 'quick view')), _createElement('a', {
                    'href': '/wishlist.php?action=add&product_id=' + this.id,
                    'aria-label': 'wishlist',
                    'className': 'card-button-wishlist',
                    'title': 'Add to Wish List'
                }, _createElement('svg', { 'title': 'icon heart' }, _createElement('use', { 'xlinkHref': '#icon-heart' })))), _createElement('div', { 'className': 'card-figcaption' }, !this.out_of_stock ? [
                    !this.has_options ? _createElement('a', {
                        'href': '/cart.php?action=add&product_id=' + this.id,
                        'aria-label': 'add to cart',
                        'className': 'button card-figcaption-button themevale_btnATC',
                        'data-product-id': this.id,
                        'key': '19421'
                    }, '\n            Add to Cart\n          ') : null,
                    this.has_options ? _createElement('a', {
                        'href': this.custom_url,
                        'data-event-type': 'product-click',
                        'className': 'button card-figcaption-button',
                        'data-product-id': this.id,
                        'key': '19423'
                    }, '\n            Choose Options\n          ') : null
                ] : null, this.out_of_stock ? _createElement('div', {
                    'className': 'button card-figcaption-button',
                    'disabled': true,
                    'key': '2456'
                }, 'Out of stock') : null)), _createElement('div', { 'className': 'card-body' }, _createElement('div', { 'className': 'card-detail' }, _createElement('p', mergeProps({
                    'className': 'card-brand',
                    'data-test-info-type': 'brandName'
                }, { dangerouslySetInnerHTML: { __html: this.brand_name } })), _createElement('h4', { 'className': 'card-title' }, _createElement('a', Object.assign({}, {
                    'href': this.custom_url,
                    'aria-label': 'product title'
                }, { dangerouslySetInnerHTML: { __html: this.name } }))), _createElement('div', { 'className': 'custom-fields' }, this.Weight_Capacity ? _createElement('p', { 'key': '2987' }, _createElement('span', {}, ' Capacity: '), ' ', this.Weight_Capacity) : null, this.Receiver_Size ? _createElement('p', { 'key': '3081' }, _createElement('span', {}, ' Receiver Size: '), ' ', this.Receiver_Size) : null, this.Tongue_Weight ? _createElement('p', { 'key': '3176' }, _createElement('span', {}, ' GTW: '), ' ', this.Tongue_Weight) : null, this.Handle_Type ? _createElement('p', { 'key': '3261' }, _createElement('span', {}, ' Handle Type: '), ' ', this.Handle_Type) : null, this.Mount_Type ? _createElement('p', { 'key': '3350' }, _createElement('span', {}, ' Mount Type: '), ' ', this.Mount_Type) : null), _createElement('div', { 'className': 'card-price-wrapper' }, _createElement('div', {
                    'className': 'card-price',
                    'data-test-info-type': 'price'
                }, this.retail_price > this.price ? _createElement('div', {
                    'className': 'price-section price-section--withoutTax rrp-price--withoutTax',
                    'key': '3558'
                }, _createElement('span', {
                    'data-product-rrp-price-without-tax': true,
                    'className': 'price price--rrp'
                }, '\n                MSRP: ', this.formatPrice(this.retail_price), '\n              ')) : null, this.on_sale ? _createElement('div', {
                    'className': 'price-section price-section--withoutTax non-sale-price--withoutTax',
                    'key': '3873'
                }, _createElement('span', {
                    'data-product-rrp-price-without-tax': true,
                    'className': 'price price--non-sale'
                }, '\n                ', this.formatPrice(this.regular_price), '\n              ')) : null, !this.retail_price && !this.regular_price || this.retail_price <= this.price || this.regular_price <= this.price ? _createElement('div', {
                    'className': 'price-section price-section--withoutTax non-sale-price--withoutTax price-none',
                    'key': '4172'
                }, _createElement('span', {
                    'data-product-non-sale-price-without-tax': true,
                    'className': 'price price--non-sale'
                })) : null, _createElement('div', { 'className': 'price-section price-section--withoutTax' }, _createElement('span', {
                    'data-product-price-without-tax': true,
                    'className': 'price price--withoutTax'
                }, '\n                ', this.formatPrice(this.price), '\n              ')))), this.view === '1' ? _createElement('div', {
                    'className': 'card-desciption',
                    'key': '4824'
                }, _createElement('p', { 'className': 'card-text' }, this.description)) : null), _createElement('div', { 'className': 'card-actions' }, _createElement('div', { 'className': 'card-figcaption' }, !this.out_of_stock ? [
                    !this.has_options ? _createElement('a', {
                        'href': '/cart.php?action=add&product_id=' + this.id,
                        'aria-label': 'add to cart',
                        'className': 'button card-figcaption-button themevale_btnATC',
                        'data-product-id': this.id,
                        'key': '50531'
                    }, '\n              Add to Cart\n            ') : null,
                    this.has_options ? _createElement('a', {
                        'href': this.custom_url,
                        'data-event-type': 'product-click',
                        'className': 'button card-figcaption-button',
                        'data-product-id': this.id,
                        'key': '50533'
                    }, '\n              Choose Options\n            ') : null
                ] : null, this.out_of_stock ? _createElement('div', {
                    'className': 'button card-figcaption-button',
                    'disabled': true,
                    'key': '5583'
                }, 'Out of stock') : null), _createElement('div', {
                    'className': 'form-wishlist',
                    'data-wishlist-add': true
                }, _createElement('button', {
                    'aria-expanded': 'true',
                    'className': 'button button--dropdown dropdown-menu-button',
                    'data-dropdown': 'wishlist-dropdown_' + this.id,
                    'onClick': window.Convermax.handleWishlistButton()
                }, _createElement('svg', { 'className': 'icon' }, _createElement('use', { 'xlinkHref': '#icon-heart' })), _createElement('span', {}, 'Add to Wish List'), _createElement('svg', { 'className': 'icon icon-down' }, _createElement('use', { 'xlinkHref': '#icon-keyboard-arrow-down' }))), _createElement('ul', {
                    'aria-hidden': 'true',
                    'className': 'dropdown-menu',
                    'data-dropdown-content': true,
                    'tabIndex': '-1'
                }, _createElement('li', {}, _createElement('a', {
                    'data-wishlist': true,
                    'href': '/wishlist.php?action=add&product_id=' + this.id
                }, '\n                Add to My Wish List\n              ')), _createElement('li', {}, _createElement('a', {
                    'data-wishlist': true,
                    'href': '/wishlist.php?action=addwishlist&product_id=' + this.id
                }, '\n                Create New Wish List\n              ')))), _createElement('label', {
                    'className': 'card-compare',
                    'htmlFor': 'compare2-' + this.id
                }, _createElement('input', {
                    'type': 'checkbox',
                    'name': 'products[]',
                    'value': this.id,
                    'id': 'compare2-' + this.id,
                    'data-compare-id': this.id,
                    'onClick': window.Convermax.handleProductCompareClick
                }), _createElement('span', {}, 'Compare'))), this.price ? _createElement('p', {
                    'className': 'affirm-as-low-as',
                    'data-amount': this.price * 100,
                    'data-affirm-type': 'logo',
                    'data-affirm-color': 'blue',
                    'key': '7026'
                }) : null)));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products cm_SearchResult' }, _createElement.apply(this, [
        'div',
        { 'className': 'productGrid productGrid--maxCol2 cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []